<template>
  <div class="page__wrapper">
    <ag-grid
      ref="summaryReportGrid"
      style="width:100%;height:100%;"
      :column-defs="columnDefs"
      :row-data.sync="tableData"
      :framework-components="frameworkComponents"
      :grid-options="gridOptions"
    >
      <!-- 查询条件 -->
      <simple-form
        v-model="searchModel"
        :form-field="searchFormField"
      >
        <template slot="tableOperation">
          <el-button @click="handleDataSearch">
            {{ $t('operation.search') }}
          </el-button>
          <el-button
            type="primary"
            @click="handleDataSearch"
          >
            {{ $t('share_monthlyTemplate.uploadNewTemplate') }}
          </el-button>
        </template>
      </simple-form>
    </ag-grid>
  </div>
</template>

<script>
import Vue from 'vue'

const BASEURL = {
  list: '/system/user/list'
}

/** 自定义操作列 */
const Operation = Vue.extend({
  inject: ['handleDataSearch', 'handleExportClick', 'handleDeleteClick'],
  template: `<div style="height:100%;text-align:center;">
      <el-tooltip effect="light" style="font-size:14px;" :content="$t('operation.download')">
        <el-button type="text" style="font-size:14px;" icon="el-icon-download" @click="handleExportClick"/>
      </el-tooltip>
      <el-tooltip effect="light" :content="$t('share_monthlyTemplate.setCurrent')">
        <el-button type="text" style="font-size:14px;" icon="el-icon-document-checked" @click="handleDeleteClick" />
      </el-tooltip>
      <el-tooltip effect="light" :content="$t('operation.delete')">
        <el-button type="text" style="font-size:14px;" icon="el-icon-delete" @click="handleDeleteClick" />
      </el-tooltip>
    </div>`
})

export default {
  name: 'MonthlyTemplate',
  data () {
    return {
      searchModel: {},
      tableData: [],
      gridOptions: {},
      frameworkComponents: {}
    }
  },
  provide () {
    return {
      handleDataSearch: this.handleDataSearch,
      handleExportClick: this.handleExportClick,
      handleDeleteClick: this.handleDeleteClick
    }
  },
  computed: {
    searchFormField () {
      return [
        { slotName: 'tableOperation', col: { xs: 24, sm: 24, md: 24 }, style: { textAlign: 'right' }, labelWidth: '0' }
      ]
    },
    columnDefs () {
      return [
        {
          width: 40,
          pinned: 'left',
          suppressSizeToFit: true,
          checkboxSelection: true
        },
        {
          headerName: this.$t('summary_report.name'),
          field: 'loginName',
          minWidth: 200
        },
        {
          headerName: this.$t('summary_report.date'),
          field: 'loginName',
          minWidth: 200
        },
        {
          headerName: this.$t('field.operation'),
          suppressSizeToFit: true,
          width: 100,
          pinned: 'right',
          cellRenderer: 'Operation'
        }
      ]
    }
  },
  beforeMount () {
    this.frameworkComponents = { Operation: Operation }
  },
  mounted () {
    this.handleDataSearch()
  },
  methods: {
    handleDataSearch () {
      const params = Object.assign({}, this.$utils.parseQueryCondition(this.searchModel, this.searchFormField))
      const loadingFlag = this.$loading({ fullscreen: false, target: this.$el, text: this.$t('tip.systemLoading') })
      this.$axios
        .post(BASEURL.list, params)
        .then(resp => {
          const respData = resp.data
          this.tableData = respData.list
        })
        .finally(() => {
          loadingFlag.close()
        })
    },
    handleExportClick (row) {},
    handleDeleteClick () {}
  }
}
</script>
<style lang="less" scoped>
  .page__wrapper {
    width: 100%;
    height: 100%;
  }
</style>
